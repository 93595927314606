<template>
  <div class="pgoffice-page">
    <span style="color: red;">
      {{"请确认数据,点击保存完成受理"}}
    </span>
    <input name="orderId" class="pg-input" id="orderId" type="text" :style="{ display: 'none' }" style="width: 180px"
      v-model="params.orderId" />
    <div id="pageOfficeContent" v-html="html_code" class="office-main"></div>
  </div>
</template>

<script>
import { SimpleFinalExcel_savaPDF } from "@/api/pageOffice";

export default {
  name: "order_excel_editor",
  data: function () {
    return {
      html_code: "",
      params: "",
    };
  },
  methods: {
    Save: function () {
      document.getElementById("PageOfficeCtrl1").WebSaveAsPDF();
      let result = document.getElementById("PageOfficeCtrl1").CustomSaveResult;
      window.external.CallParentFunc(
        "PageOfficeCbFn('" + encodeURIComponent(result) + "')"
      );
      window.external.close();
    },
    Close: function () {
      window.external.CallParentFunc("PageOfficeCloseFn()");
      window.external.close();
    },
    getParam: function () {
      this.params = window.external.UserParams;
      if (this.params) {
        this.params = JSON.parse(decodeURI(this.params));
      }
    },
    init: function () {
      this.getParam();
      if (!this.params || this.params == "" || this.params == undefined) {
        alert("未获取到参数,打开失败");
        return;
      }
      SimpleFinalExcel_savaPDF(this.params).then((res) => {
        this.html_code = res;
        document.getElementById("PageOfficeCtrl1").JsFunction_OnExcelCellClick = "excelCellClick()";
      });
    },
    onCellClick: async function (Celladdress) {
      let res = await this.getValue();
      return res;
    },
    getValue: () => {
      return new Promise((reslove) => {
        setTimeout(() => {
          reslove("AAA")
        }, 3000);
      });
    }
  },
  created: function () {
    window.BeforeDocumentSaved = function (e) {
    };
    window.Save = this.Save;
    window.Close = this.Close;
    window.onCellClick = this.onCellClick;
    this.init();
  },
};
</script>
